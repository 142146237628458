import axios from "axios";
import qs from "qs";
import { getToken, tologin } from "@/utils/app";

const timeout = 30000;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
axios.defaults.timeout = timeout;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data";

const nologinWhiteUrl = [
  "https://api.tianditu.gov.cn/geocoder" // 地图接口
];

// 请求拦截
axios.interceptors.request.use(
  function (config) {
    const accessToken = getToken();

    for (let item of nologinWhiteUrl) {
      if (config.url.includes(item)) {
        return config;
      }
    }

    if (!config.headers.noLogin && accessToken && accessToken !== "undefined")
      config.headers.Authorization = accessToken;

    return config;
  },
  function (error) {
    return Promise.reject({ status: -1, message: error });
  }
);

// 返回拦截
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const response = error.response;
    if (error && error.stack && error.stack.indexOf("timeout") > -1) {
      response.data.message = "请求超时，请稍后重试";
      response.data.msg = "请求超时，请稍后重试";
      return error.response;
    } else if (
      error &&
      error.response &&
      (error.response.status == 401 || error.response.status == 403)
    ) {
      tologin();
      return Promise.reject(
        error.response.data.message ||
          error.response.data.msg ||
          "登录信息已失效，请重新登录"
      );
    } else {
      // return error.response;
      return Promise.reject(
        error.response.message || error.response.msg || "未知错误"
      );
    }
  }
);
export const paging = {
  pageNumber: 1,
  pageSize: 10
};

// 它这里设计的在每个请求中都调用一遍checked，没有直接在response监听器里面去处理
function checked(res) {
  if (!res) throw "未知错误";
  const response = res.data;

  if (!response.success || response.code !== "0000") {
    throw (
      response.msg ||
      response.message ||
      response.error_description ||
      "未知错误"
    );
  } else {
    return Promise.resolve(Object.assign(response, { success: true }));
  }
}

/**
 * get
 */
export function get(url, params, data, headers = null) {
  return axios({
    method: "get",
    url,
    params: params,
    data,
    headers: headers ? headers : {}
  }).then(checked);
}

/**
 * post
 */
export function post(url, data, params = null, headers = null) {
  let pageParams = {};
  for (let key in data) {
    if (data[key] == null || data[key] == undefined) {
      delete data[key];
    }
    if (["pageNo", "pageSize"].includes(key)) {
      let value = data[key];
      if (key === "pageNo") {
        value = Math.max(1, +value || 0);
        pageParams["pageNumber"] = value;
      }
      if (key === "pageSize") {
        value = +value || 0;
        value = value === 0 ? paging.pageSize : Math.max(1, value);
        pageParams[key] = value;
      }
    }
  }
  if (JSON.stringify(pageParams) !== "{}") {
    if (url.includes("?")) {
      url = `${url}&${qs.stringify(pageParams)}`;
    } else {
      url = `${url}?${qs.stringify(pageParams)}`;
    }
  }
  return axios({
    method: "post",
    url,
    params: params ? params : {},
    data: data,
    headers: headers ? headers : {}
  }).then(checked);
}

/**
 * put
 */
export function put(url, data, params = null, headers = null) {
  return axios({
    method: "put",
    url,
    params: params ? params : {},
    data: data,
    headers: headers ? headers : {}
  }).then(checked);
}

/**
 * delete
 */
export function del(url, data, params = null, headers = null) {
  return axios({
    method: "delete",
    url,
    params: params ? params : {},
    data: data,
    headers: headers ? headers : {}
  }).then(checked);
}
